import axios from 'axios';
import { apiUrl } from '@/env';
import { ISubprefeitura, ISubprefeituraCreate, ISubprefeituraUpdate } from '../interfaces/subprefeitura';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const subprefeituraApi = {
  async getSubprefeituras(token: string) {
    return axios.get<ISubprefeitura[]>(`${apiUrl}/api/v1/subprefeituras/`, authHeaders(token));
  },
  async createSubprefeitura(token: string, data: ISubprefeituraCreate) {
    return axios.post(`${apiUrl}/api/v1/subprefeituras/`, data, authHeaders(token));
  },
  async updateSubprefeitura(token: string, subprefeituraId: number, data: ISubprefeituraUpdate) {
    return axios.put(`${apiUrl}/api/v1/subprefeituras/${subprefeituraId}`, data, authHeaders(token));
  },
  async deleteSubprefeitura(token: string, subprefeituraId: number) {
    return axios.delete(`${apiUrl}/api/v1/subprefeituras/${subprefeituraId}`, authHeaders(token));
  },
};
