import { OrigemLocalidadeState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    origemLocalidadeLista: (state: OrigemLocalidadeState) => state.origemLocalidadeLista,
    origemLocalidade: (state: OrigemLocalidadeState) => (origemLocalidadeId: number) => {
        const origemLocalidadeListaFiltrada = state.origemLocalidadeLista.filter((origemLocalidade) =>
            origemLocalidade.id === origemLocalidadeId);
        if (origemLocalidadeListaFiltrada.length > 0) {
            return { ...origemLocalidadeListaFiltrada[0] };
        }
    },
};

const { read } = getStoreAccessors<OrigemLocalidadeState, State>('');

export const readOrigemLocalidadeLista = read(getters.origemLocalidadeLista);
export const consultarOrigemLocalidade = read(getters.origemLocalidade);
