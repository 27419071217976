import { ITipoLocalidade } from '@/interfaces/tipo_localidade';
import { TipoLocalidadeState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setTiposLocalidade(state: TipoLocalidadeState, payload: ITipoLocalidade[]) {
        state.tiposLocalidade = payload;
    },
    setTipoLocalidade(state: TipoLocalidadeState, payload: ITipoLocalidade) {
        const tiposLocalidade = state.tiposLocalidade.filter(
            (tipoLocalidade: ITipoLocalidade) => tipoLocalidade.id !== payload.id);
        tiposLocalidade.push(payload);
        state.tiposLocalidade = tiposLocalidade;
    },
    unsetTipoLocalidade(state: TipoLocalidadeState, payload: ITipoLocalidade) {
        const tiposLocalidade = state.tiposLocalidade.filter(
            (tipoLocalidade: ITipoLocalidade) => tipoLocalidade.id !== payload.id);
        state.tiposLocalidade = tiposLocalidade;
    },
};

const { commit } = getStoreAccessors<TipoLocalidadeState, State>('');

export const commitSetTipoLocalidade = commit(mutations.setTipoLocalidade);
export const commitUnsetTipoLocalidade = commit(mutations.unsetTipoLocalidade);
export const commitSetTiposLocalidade = commit(mutations.setTiposLocalidade);
