import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { ResponsavelState } from './state';

const defaultState: ResponsavelState = {
  responsaveis: [],
};

export const responsavelModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
