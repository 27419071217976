import { IBairro } from '@/interfaces/bairro';
import { BairroState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setBairros(state: BairroState, payload: IBairro[]) {
        state.bairros = payload;
    },
    setBairro(state: BairroState, payload: IBairro) {
        const bairros = state.bairros.filter((bairro: IBairro) => bairro.id !== payload.id);
        bairros.push(payload);
        state.bairros = bairros;
    },
    unsetBairro(state: BairroState, payload: IBairro) {
        const bairros = state.bairros.filter((bairro: IBairro) => bairro.id !== payload.id);
        state.bairros = bairros;
    },
};

const { commit } = getStoreAccessors<BairroState, State>('');

export const commitSetBairro = commit(mutations.setBairro);
export const commitUnsetBairro = commit(mutations.unsetBairro);
export const commitSetBairros = commit(mutations.setBairros);
