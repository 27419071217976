import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { StatusImplantacaoState } from './state';

const defaultState: StatusImplantacaoState = {
  statusImplantacao: [],
};

export const statusImplantacaoModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
