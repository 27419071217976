import { empresaApi } from '@/api/empresa';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { EmpresaState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetEmpresa, commitSetEmpresas, commitUnsetEmpresa } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { IEmpresa, IEmpresaCreate, IEmpresaUpdate } from '@/interfaces/empresa';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<EmpresaState, State>;

export const actions = {
    async actionGetEmpresas(context: MainContext) {
        try {
            const response = await empresaApi.getEmpresas(context.rootState.main.token);
            if (response) {
                commitSetEmpresas(context, response.data);
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
    async actionCreateEmpresa(context: MainContext, payload: IEmpresaCreate): Promise<boolean> {
        const loadingNotification = { content: 'salvando', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                empresaApi.createEmpresa(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetEmpresa(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Empresa cadastrada com sucesso!', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionUpdateEmpresa(
        context: MainContext,
        payload: {
            id: number,
            empresa: IEmpresaUpdate,
        },
    ): Promise<boolean> {
        const loadingNotification = { content: 'salvando empresa', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                empresaApi.updateEmpresa(context.rootState.main.token, payload.id, payload.empresa),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetEmpresa(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Empresa atualizada com sucesso', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionDeleteEmpresa(context: MainContext, empresa: IEmpresa): Promise<boolean> {
        const loadingNotification = { content: 'excluindo empresa', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                empresaApi.deleteEmpresa(context.rootState.main.token, empresa.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitUnsetEmpresa(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Empresa removida com sucesso', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            if (apiError.response?.data.detail === 'O recurso informado já consta inativo!') {
                commitUnsetEmpresa(context, empresa);
            }
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
};

const { dispatch } = getStoreAccessors<EmpresaState, State>('');

export const dispatchGetEmpresas = dispatch(actions.actionGetEmpresas);
export const dispatchCreateEmpresa = dispatch(actions.actionCreateEmpresa);
export const dispatchUpdateEmpresa = dispatch(actions.actionUpdateEmpresa);
export const dispatchDeleteEmpresa = dispatch(actions.actionDeleteEmpresa);
