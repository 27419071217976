import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { TipoMapaState } from './state';

const defaultState: TipoMapaState = {
  tiposMapa: [],
};

export const tipoMapaModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
