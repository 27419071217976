import { ITipoLogradouro } from '@/interfaces/tipo_logradouro';
import { TipoLogradouroState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setTiposLogradouro(state: TipoLogradouroState, payload: ITipoLogradouro[]) {
        state.tiposLogradouro = payload;
    },
};

const { commit } = getStoreAccessors<TipoLogradouroState, State>('');

export const commitSetTiposLogradouro = commit(mutations.setTiposLogradouro);
