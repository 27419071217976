import axios from 'axios';
import { apiUrl } from '@/env';
import { ITipoLocalidade, ITipoLocalidadeCreate, ITipoLocalidadeUpdate } from '../interfaces/tipo_localidade';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const tipoLocalidadeApi = {
  async getTiposLocalidade(token: string) {
    return axios.get<ITipoLocalidade[]>(`${apiUrl}/api/v1/tipo_localidade/`, authHeaders(token));
  },
  async createTipoLocalidade(token: string, data: ITipoLocalidadeCreate) {
    return axios.post(`${apiUrl}/api/v1/tipo_localidade/`, data, authHeaders(token));
  },
  async updateTipoLocalidade(token: string, tipoLocalidadeId: number, data: ITipoLocalidadeUpdate) {
    return axios.put(`${apiUrl}/api/v1/tipo_localidade/${tipoLocalidadeId}`, data, authHeaders(token));
  },
  async deleteTipoLocalidade(token: string, tipoLocalidadeId: number) {
    return axios.delete(`${apiUrl}/api/v1/tipo_localidade/${tipoLocalidadeId}`, authHeaders(token));
  },
};
