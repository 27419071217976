import { RegistroAcessoState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    registrosAcesso: (state: RegistroAcessoState) => state.registros_acesso,
};

const { read } = getStoreAccessors<RegistroAcessoState, State>('');

export const readRegistrosAcesso = read(getters.registrosAcesso);
