import { TipoLogradouroState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    tiposLogradouro: (state: TipoLogradouroState) => state.tiposLogradouro,
};

const { read } = getStoreAccessors<TipoLogradouroState, State>('');

export const readTiposLogradouro = read(getters.tiposLogradouro);
