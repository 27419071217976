import { pontoAcessoApi } from '@/api/ponto_acesso';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { PontoAcessoState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetMapaPontosAcesso, commitSetPontoAcesso, commitSetPontosAcesso,
    commitSetPontosAcessoAppend, commitUnsetPontoAcesso } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { IPontoAcesso, IPontoAcessoBase, IPontoAcessoSelectFilter } from '@/interfaces/ponto_acesso';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<PontoAcessoState, State>;

export const actions = {
    async actionGetPontosAcesso(context: MainContext, skip: number) {
        try {
            const response = await pontoAcessoApi.getPontosAcesso(context.rootState.main.token, skip);
            if (response) {
                if (skip > 0) {
                    commitSetPontosAcessoAppend(context, response.data);
                } else {
                    commitSetPontosAcesso(context, response.data);
                }
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
    async actionGetMapaPontosAcessoPorResponsavel(context: MainContext) {
        try {
            const response = await pontoAcessoApi.getMapaPontosAcessoPorResponsavel(context.rootState.main.token);
            if (response) {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                commitSetMapaPontosAcesso(context, blob);
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
    async actionGetPontosAcessoFiltrados(context: MainContext, payload: IPontoAcessoSelectFilter) {
        try {
            const response = await pontoAcessoApi.getPontosAcessoFiltrar(
                context.rootState.main.token, 0, payload);
            if (response) {
                commitSetPontosAcesso(context, response.data);
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
    async actionGetHistoricoPontoAcesso(context: MainContext, idPontoAcesso: number) {
        try {
            const response = await pontoAcessoApi.getHistoricoPontoAcesso(context.rootState.main.token, idPontoAcesso);
            if (response) {
                return response.data;
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
    async actionCreatePontoAcesso(context: MainContext, payload: IPontoAcessoBase): Promise<boolean> {
        const loadingNotification = { content: 'salvando ponto de acesso', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                pontoAcessoApi.createPontoAcesso(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetPontoAcesso(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, {
                content: 'Ponto de acesso cadastrado com sucesso!',
                color: 'success',
            });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionUpdatePontoAcesso(
        context: MainContext,
        payload: {
            id: number,
            pontoAcesso: IPontoAcessoBase,
        },
    ): Promise<boolean> {
        const loadingNotification = { content: 'salvando ponto de acesso', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                pontoAcessoApi.updatePontoAcesso(
                    context.rootState.main.token,
                    payload.id,
                    payload.pontoAcesso,
                ),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetPontoAcesso(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, {
                content: 'Ponto de acesso atualizado com sucesso',
                color: 'success',
            });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionDeletePontoAcesso(context: MainContext, pontoAcesso: IPontoAcesso): Promise<boolean> {
        const loadingNotification = { content: 'excluindo ponto de acesso', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                pontoAcessoApi.deletePontoAcesso(context.rootState.main.token, pontoAcesso.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitUnsetPontoAcesso(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Ponto de acesso inativado com sucesso', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            if (apiError.response?.data.detail === 'O ponto de acesso informado já consta inativo!') {
                commitUnsetPontoAcesso(context, pontoAcesso);
            }
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionMensagemErroCodigoLocalidade(context: MainContext) {
        commitAddNotification(context, {
            content: 'O código da localidade informado não foi encontrado', color: 'red' });
    },
};

const { dispatch } = getStoreAccessors<PontoAcessoState, State>('');

export const dispatchGetPontosAcesso = dispatch(actions.actionGetPontosAcesso);
export const dispatchGetMapaPontosAcessoPorResponsavel = dispatch(actions.actionGetMapaPontosAcessoPorResponsavel);
export const dispatchGetPontosAcessoFiltrados = dispatch(actions.actionGetPontosAcessoFiltrados);
export const dispatchGetHistoricoPontoAcesso = dispatch(actions.actionGetHistoricoPontoAcesso);
export const dispatchCreatePontoAcesso = dispatch(actions.actionCreatePontoAcesso);
export const dispatchUpdatePontoAcesso = dispatch(actions.actionUpdatePontoAcesso);
export const dispatchDeletePontoAcesso = dispatch(actions.actionDeletePontoAcesso);
export const dispatchMensagemErroCodigoLocalidade = dispatch(actions.actionMensagemErroCodigoLocalidade);
