import { IPontoAcesso } from '@/interfaces/ponto_acesso';
import { PontoAcessoState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setPontoAcessoLista(state: PontoAcessoState, payload: IPontoAcesso[]) {
        state.pontosAcesso = payload;
    },
    setMapaPontosAcesso(state: PontoAcessoState, payload: Blob) {
        state.mapaPontosAcesso = payload;
    },
    setPontoAcessoListaAppend(state: PontoAcessoState, payload: IPontoAcesso[]) {
        state.pontosAcesso = state.pontosAcesso.concat(payload);
    },
    setPontoAcesso(state: PontoAcessoState, payload: IPontoAcesso) {
        const pontosAcesso = state.pontosAcesso.filter((pontoAcesso: IPontoAcesso) =>
            pontoAcesso.id !== payload.id);
        pontosAcesso.push(payload);
        state.pontosAcesso = pontosAcesso;
    },
    unsetPontoAcesso(state: PontoAcessoState, payload: IPontoAcesso) {
        const pontosAcesso = state.pontosAcesso.filter((pontoAcesso: IPontoAcesso) =>
            pontoAcesso.id !== payload.id);
        state.pontosAcesso = pontosAcesso;
    },
};

const { commit } = getStoreAccessors<PontoAcessoState, State>('');

export const commitSetPontoAcesso = commit(mutations.setPontoAcesso);
export const commitSetMapaPontosAcesso = commit(mutations.setMapaPontosAcesso);
export const commitUnsetPontoAcesso = commit(mutations.unsetPontoAcesso);
export const commitSetPontosAcesso = commit(mutations.setPontoAcessoLista);
export const commitSetPontosAcessoAppend = commit(mutations.setPontoAcessoListaAppend);
