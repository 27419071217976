import axios from 'axios';
import { apiUrl } from '@/env';
import { IOrgao, IOrgaoCreate, IOrgaoUpdate } from '../interfaces/orgao';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const orgaoApi = {
  async getOrgaos(token: string) {
    return axios.get<IOrgao[]>(`${apiUrl}/api/v1/orgaos/`, authHeaders(token));
  },
  async createOrgao(token: string, data: IOrgaoCreate) {
    return axios.post(`${apiUrl}/api/v1/orgaos/`, data, authHeaders(token));
  },
  async updateOrgao(token: string, orgaoId: number, data: IOrgaoUpdate) {
    return axios.put(`${apiUrl}/api/v1/orgaos/${orgaoId}`, data, authHeaders(token));
  },
  async deleteOrgao(token: string, orgaoId: number) {
    return axios.delete(`${apiUrl}/api/v1/orgaos/${orgaoId}`, authHeaders(token));
  },
};
