import axios from 'axios';
import { apiUrl } from '@/env';
import { IBairro, IBairroCreate, IBairroUpdate } from '../interfaces/bairro';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const bairroApi = {
  async getBairros(token: string) {
    return axios.get<IBairro[]>(`${apiUrl}/api/v1/bairros/`, authHeaders(token));
  },
  async createBairro(token: string, data: IBairroCreate) {
    return axios.post(`${apiUrl}/api/v1/bairros/`, data, authHeaders(token));
  },
  async updateBairro(token: string, bairroId: number, data: IBairroUpdate) {
    return axios.put(`${apiUrl}/api/v1/bairros/${bairroId}`, data, authHeaders(token));
  },
  async deleteBairro(token: string, bairroId: number) {
    return axios.delete(`${apiUrl}/api/v1/bairros/${bairroId}`, authHeaders(token));
  },
};
