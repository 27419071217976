import { TipoPublicidadeState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    tiposPublicidade: (state: TipoPublicidadeState) => state.tiposPublicidade,
};

const { read } = getStoreAccessors<TipoPublicidadeState, State>('');

export const readTiposPublicidade = read(getters.tiposPublicidade);
