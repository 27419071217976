import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { PontoAcessoState } from './state';

const defaultState: PontoAcessoState = {
  pontosAcesso: [],
  mapaPontosAcesso: new Blob(),
};

export const pontoAcessoModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
