import axios from 'axios';
import { apiUrl } from '@/env';
import { IEmpresa, IEmpresaCreate, IEmpresaUpdate } from '../interfaces/empresa';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const empresaApi = {
  async getEmpresas(token: string) {
    return axios.get<IEmpresa[]>(`${apiUrl}/api/v1/empresas/`, authHeaders(token));
  },
  async createEmpresa(token: string, data: IEmpresaCreate) {
    return axios.post(`${apiUrl}/api/v1/empresas/`, data, authHeaders(token));
  },
  async updateEmpresa(token: string, empresaId: number, data: IEmpresaUpdate) {
    return axios.put(`${apiUrl}/api/v1/empresas/${empresaId}`, data, authHeaders(token));
  },
  async deleteEmpresa(token: string, empresaId: number) {
    return axios.delete(`${apiUrl}/api/v1/empresas/${empresaId}`, authHeaders(token));
  },
};
