import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { EmpresaState } from './state';

const defaultState: EmpresaState = {
  empresas: [],
};

export const empresaModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
