import axios from 'axios';
import { apiUrl } from '@/env';
import { ITipoMapa } from '../interfaces/tipo_mapa';
import authHeaders from './auth';

export const tipoMapaApi = {
  async getTiposMapa(token: string) {
    return axios.get<ITipoMapa[]>(`${apiUrl}/api/v1/tipo_mapa/`, authHeaders(token));
  },
};
