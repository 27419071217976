import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { TipoLogradouroState } from './state';

const defaultState: TipoLogradouroState = {
  tiposLogradouro: [],
};

export const tipoLogradouroModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
