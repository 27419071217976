import { EmpresaState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    empresas: (state: EmpresaState) => state.empresas,
    empresa: (state: EmpresaState) => (empresaId: number) => {
        const empresasFiltradas = state.empresas.filter((empresa) => empresa.id === empresaId);
        if (empresasFiltradas.length > 0) {
            return { ...empresasFiltradas[0] };
        }
    },
};

const { read } = getStoreAccessors<EmpresaState, State>('');

export const readEmpresas = read(getters.empresas);
export const consultarEmpresa = read(getters.empresa);
