import axios from 'axios';
import { apiUrl } from '@/env';
import { ILocalidade, ILocalidadeBase, ILocalidadeHistoricoAlteracoes, ILocalidadeSelectFilter } from '../interfaces/localidade';
import authHeaders from './auth';

export const localidadeApi = {
  async getHistoricoLocalidade(token: string, localidadeId: number) {
    return axios.get<ILocalidadeHistoricoAlteracoes[]>(
      `${apiUrl}/api/v1/localidade/historico/${localidadeId}`, authHeaders(token));
  },
  async getLocalidades(token: string, skip: number) {
    return axios.get<ILocalidade[]>(`${apiUrl}/api/v1/localidade/?skip=${skip}`, authHeaders(token));
  },
  async getMapaLocalidadesPorOrigem(token: string) {
    return axios.get<ArrayBuffer>(`${apiUrl}/api/v1/localidade/pdf-localidade-por-origem`, authHeaders(token));
  },
  async getLocalidadesFiltrar(token: string, skip: number, localidadeFiltro: ILocalidadeSelectFilter) {
    return axios.post(`${apiUrl}/api/v1/localidade/filtrar?skip=${skip}`, localidadeFiltro, authHeaders(token));
  },
  async createLocalidade(token: string, data: ILocalidadeBase) {
    return axios.post(`${apiUrl}/api/v1/localidade/`, data, authHeaders(token));
  },
  async updateLocalidade(token: string, localidadeId: number, data: ILocalidadeBase) {
    return axios.put(`${apiUrl}/api/v1/localidade/${localidadeId}`, data, authHeaders(token));
  },
  async deleteLocalidade(token: string, localidadeId: number) {
    return axios.delete(`${apiUrl}/api/v1/localidade/${localidadeId}`, authHeaders(token));
  },
};
