import axios from 'axios';
import { apiUrl } from '@/env';
import { IOrigemLocalidade, IOrigemLocalidadeCreate, IOrigemLocalidadeUpdate } from '../interfaces/origem_localidade';
import authHeaders from './auth';

export const origemLocalidadeApi = {
  async getOrigemLocalidadeLista(token: string) {
    return axios.get<IOrigemLocalidade[]>(`${apiUrl}/api/v1/origem_localidade/`, authHeaders(token));
  },
  async createOrigemLocalidade(token: string, data: IOrigemLocalidadeCreate) {
    return axios.post(`${apiUrl}/api/v1/origem_localidade/`, data, authHeaders(token));
  },
  async updateOrigemLocalidade(token: string, origemLocalidadeId: number, data: IOrigemLocalidadeUpdate) {
    return axios.put(`${apiUrl}/api/v1/origem_localidade/${origemLocalidadeId}`, data, authHeaders(token));
  },
  async deleteOrigemLocalidade(token: string, origemLocalidadeId: number) {
    return axios.delete(`${apiUrl}/api/v1/origem_localidade/${origemLocalidadeId}`, authHeaders(token));
  },
};
