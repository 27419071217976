import { tipoPublicidadeApi } from '@/api/tipo_publicidade';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { TipoPublicidadeState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetTiposPublicidade } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification } from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<TipoPublicidadeState, State>;

export const actions = {
    async actionGetTiposPublicidade(context: MainContext) {
        try {
            const response = await tipoPublicidadeApi.getTiposPublicidade(context.rootState.main.token);
            if (response) {
                commitSetTiposPublicidade(context, response.data);
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
};

const { dispatch } = getStoreAccessors<TipoPublicidadeState, State>('');

export const dispatchGetTiposPublicidade = dispatch(actions.actionGetTiposPublicidade);
