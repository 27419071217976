import { responsavelApi } from '@/api/responsavel';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { ResponsavelState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetResponsavel, commitSetResponsaveis, commitUnsetResponsavel } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { IResponsavel, IResponsavelCreate, IResponsavelUpdate } from '@/interfaces/responsavel';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<ResponsavelState, State>;

export const actions = {
    async actionGetResponsaveis(context: MainContext) {
        try {
            const response = await responsavelApi.getResponsaveis(context.rootState.main.token);
            if (response) {
                commitSetResponsaveis(context, response.data);
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
    async actionCreateResponsavel(context: MainContext, payload: IResponsavelCreate): Promise<boolean> {
        const loadingNotification = { content: 'salvando responsável', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                responsavelApi.createResponsavel(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetResponsavel(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, {
                content: 'Responsável cadastrado com sucesso!',
                color: 'success',
            });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionUpdateResponsavel(
        context: MainContext,
        payload: {
            id: number,
            responsavel: IResponsavelUpdate,
        },
    ): Promise<boolean> {
        const loadingNotification = { content: 'salvando responsável', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                responsavelApi.updateResponsavel(
                    context.rootState.main.token,
                    payload.id,
                    payload.responsavel,
                ),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetResponsavel(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, {
                content: 'Responsável atualizado com sucesso',
                color: 'success',
            });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionDeleteResponsavel(context: MainContext, responsavel: IResponsavel): Promise<boolean> {
        const loadingNotification = { content: 'excluindo responsável', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                responsavelApi.deleteResponsavel(context.rootState.main.token, responsavel.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitUnsetResponsavel(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Responsável inativado com sucesso', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            if (apiError.response?.data.detail === 'O responsável informado já consta inativo!') {
                commitUnsetResponsavel(context, responsavel);
            }
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
};

const { dispatch } = getStoreAccessors<ResponsavelState, State>('');

export const dispatchGetResponsaveis = dispatch(actions.actionGetResponsaveis);
export const dispatchCreateResponsavel = dispatch(actions.actionCreateResponsavel);
export const dispatchUpdateResponsavel = dispatch(actions.actionUpdateResponsavel);
export const dispatchDeleteResponsavel = dispatch(actions.actionDeleteResponsavel);
