import { subprefeituraApi } from '@/api/subprefeitura';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { SubprefeituraState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetSubprefeitura, commitSetSubprefeituras, commitUnsetSubprefeitura } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { ISubprefeitura, ISubprefeituraCreate, ISubprefeituraUpdate } from '@/interfaces/subprefeitura';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<SubprefeituraState, State>;

export const actions = {
    async actionGetSubprefeituras(context: MainContext) {
        try {
            const response = await subprefeituraApi.getSubprefeituras(context.rootState.main.token);
            if (response) {
                commitSetSubprefeituras(context, response.data);
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
    async actionCreateSubprefeitura(context: MainContext, payload: ISubprefeituraCreate): Promise<boolean> {
        const loadingNotification = { content: 'salvando', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                subprefeituraApi.createSubprefeitura(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetSubprefeitura(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Subprefeitura cadastrada com sucesso!', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionUpdateSubprefeitura(
        context: MainContext,
        payload: {
            id: number,
            subprefeitura: ISubprefeituraUpdate,
        },
    ): Promise<boolean> {
        const loadingNotification = { content: 'salvando subprefeitura', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                subprefeituraApi.updateSubprefeitura(context.rootState.main.token, payload.id, payload.subprefeitura),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetSubprefeitura(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Subprefeitura atualizada com sucesso', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionDeleteSubprefeitura(context: MainContext, subprefeitura: ISubprefeitura): Promise<boolean> {
        const loadingNotification = { content: 'excluindo subprefeitura', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                subprefeituraApi.deleteSubprefeitura(context.rootState.main.token, subprefeitura.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitUnsetSubprefeitura(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Subprefeitura inativada com sucesso', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            if (apiError.response?.data.detail === 'A subprefeitura selecionada já consta inativa!') {
                commitUnsetSubprefeitura(context, subprefeitura);
            }
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
};

const { dispatch } = getStoreAccessors<SubprefeituraState, State>('');

export const dispatchGetSubprefeituras = dispatch(actions.actionGetSubprefeituras);
export const dispatchCreateSubprefeitura = dispatch(actions.actionCreateSubprefeitura);
export const dispatchUpdateSubprefeitura = dispatch(actions.actionUpdateSubprefeitura);
export const dispatchDeleteSubprefeitura = dispatch(actions.actionDeleteSubprefeitura);
