import { StatusImplantacaoState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    statusImplantacao: (state: StatusImplantacaoState) => state.statusImplantacao,
};

const { read } = getStoreAccessors<StatusImplantacaoState, State>('');

export const readStatusImplantacao = read(getters.statusImplantacao);
