import { IRequisicaoMapa } from '@/interfaces/requisicao_mapa';
import { RequisicaoMapaState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setRequisicaoMapa(state: RequisicaoMapaState, payload: IRequisicaoMapa) {
        const requisicoesMapa = state.requisicoesMapa.filter((requisicaoMapa: IRequisicaoMapa) =>
            requisicaoMapa.id !== payload.id);
        requisicoesMapa.push(payload);
        state.requisicoesMapa = requisicoesMapa;
    },
    setRequisicoesMapa(state: RequisicaoMapaState, payload: IRequisicaoMapa[]) {
        state.requisicoesMapa = payload;
    },
    unsetRequisicaoMapa(state: RequisicaoMapaState, payload: IRequisicaoMapa) {
        const requisicoesMapa = state.requisicoesMapa.filter((requisicaoMapa: IRequisicaoMapa) =>
            requisicaoMapa.id !== payload.id);
        state.requisicoesMapa = requisicoesMapa;
    },
};

const { commit } = getStoreAccessors<RequisicaoMapaState, State>('');

export const commitSetRequisicaoMapa = commit(mutations.setRequisicaoMapa);
export const commitSetRequisicoesMapa = commit(mutations.setRequisicoesMapa);
export const commitUnsetRequisicaoMapa = commit(mutations.unsetRequisicaoMapa);
