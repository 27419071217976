import { IResponsavel } from '@/interfaces/responsavel';
import { ResponsavelState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setResponsavelLista(state: ResponsavelState, payload: IResponsavel[]) {
        state.responsaveis = payload;
    },
    setResponsavel(state: ResponsavelState, payload: IResponsavel) {
        const responsaveis = state.responsaveis.filter((responsavel: IResponsavel) =>
            responsavel.id !== payload.id);
        responsaveis.push(payload);
        state.responsaveis = responsaveis;
    },
    unsetResponsavel(state: ResponsavelState, payload: IResponsavel) {
        const responsaveis = state.responsaveis.filter((responsavel: IResponsavel) =>
            responsavel.id !== payload.id);
        state.responsaveis = responsaveis;
    },
};

const { commit } = getStoreAccessors<ResponsavelState, State>('');

export const commitSetResponsavel = commit(mutations.setResponsavel);
export const commitUnsetResponsavel = commit(mutations.unsetResponsavel);
export const commitSetResponsaveis = commit(mutations.setResponsavelLista);
