import { registroAcessoApi } from '@/api/registro_acesso';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { RegistroAcessoState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetRegistroAcesso, commitSetRegistrosAcesso, commitUnsetRegistroAcesso } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { IRegistroAcesso, IRegistroAcessoCreate } from '@/interfaces/registro_acesso';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<RegistroAcessoState, State>;

export const actions = {
    async actionGetRegistrosAcesso(context: MainContext) {
        try {
            const response = await registroAcessoApi.getRegistrosAcesso(context.rootState.main.token);
            if (response) {
                commitSetRegistrosAcesso(context, response.data);
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
    async actionCreateRegistroAcesso(context: MainContext, payload: IRegistroAcessoCreate): Promise<boolean> {
        const loadingNotification = { content: 'salvando', showProgress: true };
        try {
            const response = (await Promise.all([
                registroAcessoApi.createRegistroAcesso(payload.token_usuario, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            return false;
        }
    },
};

const { dispatch } = getStoreAccessors<RegistroAcessoState, State>('');

export const dispatchGetRegistrosAcesso = dispatch(actions.actionGetRegistrosAcesso);
export const dispatchCreateRegistroAcesso = dispatch(actions.actionCreateRegistroAcesso);
