import { IRegistroAcesso } from '@/interfaces/registro_acesso';
import { RegistroAcessoState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setRegistrosAcesso(state: RegistroAcessoState, payload: IRegistroAcesso[]) {
        state.registros_acesso = payload;
    },
    setRegistroAcesso(state: RegistroAcessoState, payload: IRegistroAcesso) {
        const registrosAcesso = state.registros_acesso.filter((registroAcesso: IRegistroAcesso) =>
            registroAcesso.id !== payload.id);
        registrosAcesso.push(payload);
        state.registros_acesso = registrosAcesso;
    },
    unsetRegistroAcesso(state: RegistroAcessoState, payload: IRegistroAcesso) {
        const registrosAcesso = state.registros_acesso.filter((registroAcesso: IRegistroAcesso) =>
            registroAcesso.id !== payload.id);
        state.registros_acesso = registrosAcesso;
    },
};

const { commit } = getStoreAccessors<RegistroAcessoState, State>('');

export const commitSetRegistrosAcesso = commit(mutations.setRegistrosAcesso);
export const commitUnsetRegistroAcesso = commit(mutations.unsetRegistroAcesso);
export const commitSetRegistroAcesso = commit(mutations.setRegistroAcesso);
