import { requisicaoMapaApi } from '@/api/requisicao_mapa';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { RequisicaoMapaState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetRequisicaoMapa, commitSetRequisicoesMapa, commitUnsetRequisicaoMapa } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { IRequisicaoMapa, IRequisicaoMapaCreate, IRequisicaoMapaSelectFilter } from '@/interfaces/requisicao_mapa';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<RequisicaoMapaState, State>;

export const actions = {
    async actionGetRequisicoesMapa(context: MainContext, skip: number) {
        try {
            const response = await requisicaoMapaApi.getRequisicoesMapa(context.rootState.main.token, skip);
            if (response) {
                commitSetRequisicoesMapa(context, response.data);
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
    async actionCreateRequisicaoMapa(context: MainContext, payload: IRequisicaoMapaCreate): Promise<boolean> {
        const loadingNotification = { content: 'salvando requisicao de mapa', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                requisicaoMapaApi.createRequisicaoMapa(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetRequisicaoMapa(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, {
                content: 'Requisição de mapa cadastrada com sucesso!',
                color: 'success',
            });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionGetRequisicoesMapaFiltradas(context: MainContext, payload: IRequisicaoMapaSelectFilter) {
        try {
            const response = await requisicaoMapaApi.getRequisicoesMapaFiltrar(
                context.rootState.main.token, 0, payload);
            if (response) {
                commitSetRequisicoesMapa(context, response.data);
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
};

const { dispatch } = getStoreAccessors<RequisicaoMapaState, State>('');

export const dispatchGetRequisicoesMapa = dispatch(actions.actionGetRequisicoesMapa);
export const dispatchCreateRequisicaoMapa = dispatch(actions.actionCreateRequisicaoMapa);
export const dispatchGetRequisicoesMapaFiltradas = dispatch(actions.actionGetRequisicoesMapaFiltradas);
