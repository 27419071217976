import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import VueMask from 'v-mask';

import { mainModule } from './main';
import { State } from './state';
import { adminModule } from './admin';
import { empresaModule } from './empresa';
import { orgaoModule } from './orgao';
import { tipoLocalidadeModule } from './tipo_localidade';
import { origemLocalidadeModule } from './origem_localidade';
import { responsavelModule } from './responsavel';
import { localidadeModule } from './localidade';
import { subprefeituraModule } from './subprefeitura';
import { distritoModule } from './distrito';
import { bairroModule } from './bairro';
import { regiaoModule } from './regiao';
import { tipoLogradouroModule } from './tipo_logradouro';
import { statusImplantacaoModule } from './status_implantacao';
import { tipoPublicidadeModule } from './tipo_publicidade';
import { pontoAcessoModule } from './ponto_acesso';
import { tipoMapaModule } from './tipo_mapa';
import { registroAcessoModule } from './registro_acesso';
import { requisicaoMapaModule } from './requisicao_mapa';

Vue.use(Vuex);
Vue.use(VueMask, {
  placeholders: {
    F: /[0-9a-fA-F]/,
  },
});

const storeOptions: StoreOptions<State> = {
  modules: {
    main: mainModule,
    admin: adminModule,
    empresa: empresaModule,
    orgao: orgaoModule,
    tipoLocalidade: tipoLocalidadeModule,
    origemLocalidade: origemLocalidadeModule,
    responsavel: responsavelModule,
    localidade: localidadeModule,
    subprefeitura: subprefeituraModule,
    distrito: distritoModule,
    bairro: bairroModule,
    regiao: regiaoModule,
    tipoLogradouro: tipoLogradouroModule,
    statusImplantacao: statusImplantacaoModule,
    tipoPublicidade: tipoPublicidadeModule,
    pontoAcesso: pontoAcessoModule,
    tipoMapa: tipoMapaModule,
    registroAcesso: registroAcessoModule,
    requisicaoMapa: requisicaoMapaModule,
  },
};

export const store = new Vuex.Store<State>(storeOptions);

export default store;
