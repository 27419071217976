import { IOrigemLocalidade } from '@/interfaces/origem_localidade';
import { OrigemLocalidadeState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setOrigemLocalidadeLista(state: OrigemLocalidadeState, payload: IOrigemLocalidade[]) {
        state.origemLocalidadeLista = payload;
    },
    setOrigemLocalidade(state: OrigemLocalidadeState, payload: IOrigemLocalidade) {
        const origemLocalidadeLista = state.origemLocalidadeLista.filter((origemLocalidade: IOrigemLocalidade) =>
            origemLocalidade.id !== payload.id);
        origemLocalidadeLista.push(payload);
        state.origemLocalidadeLista = origemLocalidadeLista;
    },
    unsetOrigemLocalidade(state: OrigemLocalidadeState, payload: IOrigemLocalidade) {
        const origemLocalidadeLista = state.origemLocalidadeLista.filter((origemLocalidade: IOrigemLocalidade) =>
            origemLocalidade.id !== payload.id);
        state.origemLocalidadeLista = origemLocalidadeLista;
    },
};

const { commit } = getStoreAccessors<OrigemLocalidadeState, State>('');

export const commitSetOrigemLocalidade = commit(mutations.setOrigemLocalidade);
export const commitUnsetOrigemLocalidade = commit(mutations.unsetOrigemLocalidade);
export const commitSetOrigemLocalidadeLista = commit(mutations.setOrigemLocalidadeLista);
