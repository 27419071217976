import { ILocalidade } from '@/interfaces/localidade';
import { LocalidadeState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setLocalidadeLista(state: LocalidadeState, payload: ILocalidade[]) {
        state.localidades = payload;
    },
    setMapaLocalidades(state: LocalidadeState, payload: Blob) {
        state.mapaLocalidades = payload;
    },
    setLocalidadeListaAppend(state: LocalidadeState, payload: ILocalidade[]) {
        state.localidades = state.localidades.concat(payload);
    },
    setLocalidade(state: LocalidadeState, payload: ILocalidade) {
        const localidades = state.localidades.filter((localidade: ILocalidade) =>
            localidade.id !== payload.id);
        localidades.push(payload);
        state.localidades = localidades;
    },
    unsetLocalidade(state: LocalidadeState, payload: ILocalidade) {
        const localidades = state.localidades.filter((localidade: ILocalidade) =>
            localidade.id !== payload.id);
        state.localidades = localidades;
    },
};

const { commit } = getStoreAccessors<LocalidadeState, State>('');

export const commitSetLocalidade = commit(mutations.setLocalidade);
export const commitSetMapaLocalidades = commit(mutations.setMapaLocalidades);
export const commitUnsetLocalidade = commit(mutations.unsetLocalidade);
export const commitSetLocalidades = commit(mutations.setLocalidadeLista);
export const commitSetLocalidadesAppend = commit(mutations.setLocalidadeListaAppend);
