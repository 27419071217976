import { RequisicaoMapaState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    requisicoesMapa: (state: RequisicaoMapaState) => state.requisicoesMapa,
    requisicaoMapa: (state: RequisicaoMapaState) => (requisicaoMapaId: number) => {
        const requisicoesMapaFiltradas = state.requisicoesMapa.filter((requisicaoMapa) =>
            requisicaoMapa.id === requisicaoMapaId);
        if (requisicoesMapaFiltradas.length > 0) {
            return { ...requisicoesMapaFiltradas[0] };
        }
    },
};

const { read } = getStoreAccessors<RequisicaoMapaState, State>('');

export const readRequisicoesMapa = read(getters.requisicoesMapa);
export const consultarRequisicaoMapa = read(getters.requisicaoMapa);
