import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { RequisicaoMapaState } from './state';

const defaultState: RequisicaoMapaState = {
  requisicoesMapa: [],
};

export const requisicaoMapaModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
