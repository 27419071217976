import axios from 'axios';
import { apiUrl } from '@/env';
import { IStatusImplantacao } from '../interfaces/status_implantacao';
import authHeaders from './auth';

export const statusImplantacaoApi = {
  async getStatusImplantacao(token: string) {
    return axios.get<IStatusImplantacao[]>(`${apiUrl}/api/v1/status_implantacao/`, authHeaders(token));
  },
};
