import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { TipoPublicidadeState } from './state';

const defaultState: TipoPublicidadeState = {
  tiposPublicidade: [],
};

export const tipoPublicidadeModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
