import axios from 'axios';
import { apiUrl } from '@/env';
import { IRegistroAcesso, IRegistroAcessoCreate } from '../interfaces/registro_acesso';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const registroAcessoApi = {
  async getRegistrosAcesso(token: string) {
    return axios.get<IRegistroAcesso[]>(`${apiUrl}/api/v1/registro_acesso/`, authHeaders(token));
  },
  async createRegistroAcesso(token: string, data: IRegistroAcessoCreate) {
    return axios.post(`${apiUrl}/api/v1/registro_acesso/`, data, authHeaders(token));
  },
};
