import { DistritoState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    distritos: (state: DistritoState) => state.distritos,
    distrito: (state: DistritoState) => (distritoId: number) => {
        const distritosFiltrados = state.distritos.filter((distrito) => distrito.id === distritoId);
        if (distritosFiltrados.length > 0) {
            return { ...distritosFiltrados[0] };
        }
    },
};

const { read } = getStoreAccessors<DistritoState, State>('');

export const readDistritos = read(getters.distritos);
export const consultarDistrito = read(getters.distrito);
