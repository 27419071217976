import axios from 'axios';
import { apiUrl } from '@/env';
import { IRequisicaoMapa, IRequisicaoMapaCreate, IRequisicaoMapaSelectFilter } from '../interfaces/requisicao_mapa';
import authHeaders from './auth';

export const requisicaoMapaApi = {
  async getRequisicoesMapa(token: string, skip: number) {
    return axios.get<IRequisicaoMapa[]>(`${apiUrl}/api/v1/requisicao_mapa/?skip=${skip}`, authHeaders(token));
  },
  async createRequisicaoMapa(token: string, data: IRequisicaoMapaCreate) {
    return axios.post(`${apiUrl}/api/v1/requisicao_mapa/`, data, authHeaders(token));
  },
  async getRequisicoesMapaFiltrar(token: string, skip: number, RequisicaoMapaFiltro: IRequisicaoMapaSelectFilter) {
    return axios.post(`${apiUrl}/api/v1/requisicao_mapa/filtrar?skip=${skip}`,
            RequisicaoMapaFiltro, authHeaders(token));
  },
};
