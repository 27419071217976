import axios from 'axios';
import { apiUrl } from '@/env';
import { IResponsavel, IResponsavelCreate, IResponsavelUpdate } from '../interfaces/responsavel';
import authHeaders from './auth';

export const responsavelApi = {
  async getResponsaveis(token: string) {
    return axios.get<IResponsavel[]>(`${apiUrl}/api/v1/responsavel/`, authHeaders(token));
  },
  async createResponsavel(token: string, data: IResponsavelCreate) {
    return axios.post(`${apiUrl}/api/v1/responsavel/`, data, authHeaders(token));
  },
  async updateResponsavel(token: string, responsavelId: number, data: IResponsavelUpdate) {
    return axios.put(`${apiUrl}/api/v1/responsavel/${responsavelId}`, data, authHeaders(token));
  },
  async deleteResponsavel(token: string, responsavelId: number) {
    return axios.delete(`${apiUrl}/api/v1/responsavel/${responsavelId}`, authHeaders(token));
  },
};
