import { bairroApi } from '@/api/bairro';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { BairroState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetBairro, commitSetBairros, commitUnsetBairro } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { IBairro, IBairroCreate, IBairroUpdate } from '@/interfaces/bairro';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<BairroState, State>;

export const actions = {
    async actionGetBairros(context: MainContext) {
        try {
            const response = await bairroApi.getBairros(context.rootState.main.token);
            if (response) {
                commitSetBairros(context, response.data);
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
    async actionCreateBairro(context: MainContext, payload: IBairroCreate): Promise<boolean> {
        const loadingNotification = { content: 'salvando', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                bairroApi.createBairro(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetBairro(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Bairro cadastrado com sucesso!', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionUpdateBairro(
        context: MainContext,
        payload: {
            id: number,
            bairro: IBairroUpdate,
        },
    ): Promise<boolean> {
        const loadingNotification = { content: 'salvando bairro', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                bairroApi.updateBairro(context.rootState.main.token, payload.id, payload.bairro),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetBairro(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Bairro atualizado com sucesso', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionDeleteBairro(context: MainContext, bairro: IBairro): Promise<boolean> {
        const loadingNotification = { content: 'excluindo bairro', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                bairroApi.deleteBairro(context.rootState.main.token, bairro.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitUnsetBairro(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Bairro inativado com sucesso', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            if (apiError.response?.data.detail === 'O bairro selecionado já consta inativo!') {
                commitUnsetBairro(context, bairro);
            }
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
};

const { dispatch } = getStoreAccessors<BairroState, State>('');

export const dispatchGetBairros = dispatch(actions.actionGetBairros);
export const dispatchCreateBairro = dispatch(actions.actionCreateBairro);
export const dispatchUpdateBairro = dispatch(actions.actionUpdateBairro);
export const dispatchDeleteBairro = dispatch(actions.actionDeleteBairro);
