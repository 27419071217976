import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { RegiaoState } from './state';

const defaultState: RegiaoState = {
  regioes: [],
};

export const regiaoModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
