import axios from 'axios';
import { apiUrl } from '@/env';
import { IDistrito, IDistritoCreate, IDistritoUpdate } from '../interfaces/distrito';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const distritoApi = {
  async getDistritos(token: string) {
    return axios.get<IDistrito[]>(`${apiUrl}/api/v1/distritos/`, authHeaders(token));
  },
  async createDistrito(token: string, data: IDistritoCreate) {
    return axios.post(`${apiUrl}/api/v1/distritos/`, data, authHeaders(token));
  },
  async updateDistrito(token: string, distritoId: number, data: IDistritoUpdate) {
    return axios.put(`${apiUrl}/api/v1/distritos/${distritoId}`, data, authHeaders(token));
  },
  async deleteDistrito(token: string, distritoId: number) {
    return axios.delete(`${apiUrl}/api/v1/distritos/${distritoId}`, authHeaders(token));
  },
};
