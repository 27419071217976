import { ITipoMapa } from '@/interfaces/tipo_mapa';
import { TipoMapaState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setTiposMapa(state: TipoMapaState, payload: ITipoMapa[]) {
        state.tiposMapa = payload;
    },
};

const { commit } = getStoreAccessors<TipoMapaState, State>('');

export const commitSetTiposMapa = commit(mutations.setTiposMapa);
