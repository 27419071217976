import { tipoMapaApi } from '@/api/tipo_mapa';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { TipoMapaState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetTiposMapa } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification } from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<TipoMapaState, State>;

export const actions = {
    async actionGetTiposMapa(context: MainContext) {
        try {
            const response = await tipoMapaApi.getTiposMapa(context.rootState.main.token);
            if (response) {
                commitSetTiposMapa(context, response.data);
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
};

const { dispatch } = getStoreAccessors<TipoMapaState, State>('');

export const dispatchGetTiposMapa = dispatch(actions.actionGetTiposMapa);
