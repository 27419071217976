import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { DistritoState } from './state';

const defaultState: DistritoState = {
  distritos: [],
};

export const distritoModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
