import { RegiaoState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    regioes: (state: RegiaoState) => state.regioes,
};

const { read } = getStoreAccessors<RegiaoState, State>('');

export const readRegioes = read(getters.regioes);
