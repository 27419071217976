import { IDistrito } from '@/interfaces/distrito';
import { DistritoState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setDistritos(state: DistritoState, payload: IDistrito[]) {
        state.distritos = payload;
    },
    setDistrito(state: DistritoState, payload: IDistrito) {
        const distritos = state.distritos.filter((distrito: IDistrito) => distrito.id !== payload.id);
        distritos.push(payload);
        state.distritos = distritos;
    },
    unsetDistrito(state: DistritoState, payload: IDistrito) {
        const distritos = state.distritos.filter((distrito: IDistrito) => distrito.id !== payload.id);
        state.distritos = distritos;
    },
};

const { commit } = getStoreAccessors<DistritoState, State>('');

export const commitSetDistrito = commit(mutations.setDistrito);
export const commitUnsetDistrito = commit(mutations.unsetDistrito);
export const commitSetDistritos = commit(mutations.setDistritos);
