import { IRegiao } from '@/interfaces/regiao';
import { RegiaoState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setRegioes(state: RegiaoState, payload: IRegiao[]) {
        state.regioes = payload;
    },
};

const { commit } = getStoreAccessors<RegiaoState, State>('');

export const commitSetRegioes = commit(mutations.setRegioes);
