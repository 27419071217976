import { origemLocalidadeApi } from '@/api/origem_localidade';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { OrigemLocalidadeState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetOrigemLocalidade, commitSetOrigemLocalidadeLista, commitUnsetOrigemLocalidade } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { IOrigemLocalidade, IOrigemLocalidadeCreate, IOrigemLocalidadeUpdate } from '@/interfaces/origem_localidade';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<OrigemLocalidadeState, State>;

export const actions = {
    async actionGetOrigemLocalidadeLista(context: MainContext) {
        try {
            const response = await origemLocalidadeApi.getOrigemLocalidadeLista(context.rootState.main.token);
            if (response) {
                commitSetOrigemLocalidadeLista(context, response.data);
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
    async actionCreateOrigemLocalidade(context: MainContext, payload: IOrigemLocalidadeCreate): Promise<boolean> {
        const loadingNotification = { content: 'salvando origem da localidade', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                origemLocalidadeApi.createOrigemLocalidade(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetOrigemLocalidade(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, {
                content: 'Origem da localidade cadastrada com sucesso!',
                color: 'success',
            });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionUpdateOrigemLocalidade(
        context: MainContext,
        payload: {
            id: number,
            origemLocalidade: IOrigemLocalidadeUpdate,
        },
    ): Promise<boolean> {
        const loadingNotification = { content: 'salvando origem da localidade', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                origemLocalidadeApi.updateOrigemLocalidade(
                    context.rootState.main.token,
                    payload.id,
                    payload.origemLocalidade,
                ),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetOrigemLocalidade(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, {
                content: 'Origem da localidade atualizada com sucesso',
                color: 'success',
            });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionDeleteOrigemLocalidade(context: MainContext, origemLocalidade: IOrigemLocalidade): Promise<boolean> {
        const loadingNotification = { content: 'excluindo origem da localidade', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                origemLocalidadeApi.deleteOrigemLocalidade(context.rootState.main.token, origemLocalidade.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitUnsetOrigemLocalidade(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Origem da localidade inativada com sucesso', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            if (apiError.response?.data.detail === 'A origem da localidade selecionada já consta inativa!') {
                commitUnsetOrigemLocalidade(context, origemLocalidade);
            }
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
};

const { dispatch } = getStoreAccessors<OrigemLocalidadeState, State>('');

export const dispatchGetOrigemLocalidadeLista = dispatch(actions.actionGetOrigemLocalidadeLista);
export const dispatchCreateOrigemLocalidade = dispatch(actions.actionCreateOrigemLocalidade);
export const dispatchUpdateOrigemLocalidade = dispatch(actions.actionUpdateOrigemLocalidade);
export const dispatchDeleteOrigemLocalidade = dispatch(actions.actionDeleteOrigemLocalidade);
