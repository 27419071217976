import { tipoLocalidadeApi } from '@/api/tipo_localidade';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { TipoLocalidadeState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetTipoLocalidade, commitSetTiposLocalidade, commitUnsetTipoLocalidade } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { ITipoLocalidade, ITipoLocalidadeCreate, ITipoLocalidadeUpdate } from '@/interfaces/tipo_localidade';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<TipoLocalidadeState, State>;

export const actions = {
    async actionGetTiposLocalidade(context: MainContext) {
        try {
            const response = await tipoLocalidadeApi.getTiposLocalidade(context.rootState.main.token);
            if (response) {
                commitSetTiposLocalidade(context, response.data);
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
    async actionCreateTipoLocalidade(context: MainContext, payload: ITipoLocalidadeCreate): Promise<boolean> {
        const loadingNotification = { content: 'salvando', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                tipoLocalidadeApi.createTipoLocalidade(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetTipoLocalidade(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Tipo de localidade cadastrado com sucesso!', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionUpdateTipoLocalidade(
        context: MainContext,
        payload: {
            id: number,
            tipoLocalidade: ITipoLocalidadeUpdate,
        },
    ): Promise<boolean> {
        const loadingNotification = { content: 'salvando tipo de localidade', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                tipoLocalidadeApi.updateTipoLocalidade(
                    context.rootState.main.token, payload.id, payload.tipoLocalidade),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetTipoLocalidade(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Tipo de localidade atualizado com sucesso', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionDeleteTipoLocalidade(context: MainContext, tipoLocalidade: ITipoLocalidade): Promise<boolean> {
        const loadingNotification = { content: 'excluindo tipo de localidade', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                tipoLocalidadeApi.deleteTipoLocalidade(context.rootState.main.token, tipoLocalidade.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitUnsetTipoLocalidade(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Tipo de localidade inativado com sucesso', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            if (apiError.response?.data.detail === 'O tipo de localidade selecionado já consta inativo!') {
                commitUnsetTipoLocalidade(context, tipoLocalidade);
            }
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
};

const { dispatch } = getStoreAccessors<TipoLocalidadeState, State>('');

export const dispatchGetTiposLocalidade = dispatch(actions.actionGetTiposLocalidade);
export const dispatchCreateTipoLocalidade = dispatch(actions.actionCreateTipoLocalidade);
export const dispatchUpdateTipoLocalidade = dispatch(actions.actionUpdateTipoLocalidade);
export const dispatchDeleteTipoLocalidade = dispatch(actions.actionDeleteTipoLocalidade);
