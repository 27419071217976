import { OrgaoState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    orgaos: (state: OrgaoState) => state.orgaos,
    orgao: (state: OrgaoState) => (orgaoId: number) => {
        const orgaosFiltrados = state.orgaos.filter((orgao) => orgao.id === orgaoId);
        if (orgaosFiltrados.length > 0) {
            return { ...orgaosFiltrados[0] };
        }
    },
};

const { read } = getStoreAccessors<OrgaoState, State>('');

export const readOrgaos = read(getters.orgaos);
export const consultarOrgao = read(getters.orgao);
