import axios from 'axios';
import { apiUrl } from '@/env';
import { ITipoPublicidade } from '../interfaces/tipo_publicidade';
import authHeaders from './auth';

export const tipoPublicidadeApi = {
  async getTiposPublicidade(token: string) {
    return axios.get<ITipoPublicidade[]>(`${apiUrl}/api/v1/tipo_publicidade/`, authHeaders(token));
  },
};
