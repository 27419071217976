import { TipoMapaState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    tiposMapa: (state: TipoMapaState) => state.tiposMapa,
};

const { read } = getStoreAccessors<TipoMapaState, State>('');

export const readTiposMapa = read(getters.tiposMapa);
