import { ISubprefeitura } from '@/interfaces/subprefeitura';
import { SubprefeituraState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setSubprefeituras(state: SubprefeituraState, payload: ISubprefeitura[]) {
        state.subprefeituras = payload;
    },
    setSubprefeitura(state: SubprefeituraState, payload: ISubprefeitura) {
        const subprefeituras = state.subprefeituras.filter(
            (subprefeitura: ISubprefeitura) => subprefeitura.id !== payload.id);
        subprefeituras.push(payload);
        state.subprefeituras = subprefeituras;
    },
    unsetSubprefeitura(state: SubprefeituraState, payload: ISubprefeitura) {
        const subprefeituras = state.subprefeituras.filter(
            (subprefeitura: ISubprefeitura) => subprefeitura.id !== payload.id);
        state.subprefeituras = subprefeituras;
    },
};

const { commit } = getStoreAccessors<SubprefeituraState, State>('');

export const commitSetSubprefeitura = commit(mutations.setSubprefeitura);
export const commitUnsetSubprefeitura = commit(mutations.unsetSubprefeitura);
export const commitSetSubprefeituras = commit(mutations.setSubprefeituras);
