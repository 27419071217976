import { distritoApi } from '@/api/distrito';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { DistritoState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetDistrito, commitSetDistritos, commitUnsetDistrito } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { IDistrito, IDistritoCreate, IDistritoUpdate } from '@/interfaces/distrito';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<DistritoState, State>;

export const actions = {
    async actionGetDistritos(context: MainContext) {
        try {
            const response = await distritoApi.getDistritos(context.rootState.main.token);
            if (response) {
                commitSetDistritos(context, response.data);
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
    async actionCreateDistrito(context: MainContext, payload: IDistritoCreate): Promise<boolean> {
        const loadingNotification = { content: 'salvando', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                distritoApi.createDistrito(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetDistrito(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Distrito cadastrado com sucesso!', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionUpdateDistrito(
        context: MainContext,
        payload: {
            id: number,
            distrito: IDistritoUpdate,
        },
    ): Promise<boolean> {
        const loadingNotification = { content: 'salvando distrito', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                distritoApi.updateDistrito(context.rootState.main.token, payload.id, payload.distrito),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetDistrito(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Distrito atualizado com sucesso', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionDeleteDistrito(context: MainContext, distrito: IDistrito): Promise<boolean> {
        const loadingNotification = { content: 'excluindo distrito', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                distritoApi.deleteDistrito(context.rootState.main.token, distrito.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitUnsetDistrito(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Distrito inativado com sucesso', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            if (apiError.response?.data.detail === 'O distrito selecionado já consta inativo!') {
                commitUnsetDistrito(context, distrito);
            }
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
};

const { dispatch } = getStoreAccessors<DistritoState, State>('');

export const dispatchGetDistritos = dispatch(actions.actionGetDistritos);
export const dispatchCreateDistrito = dispatch(actions.actionCreateDistrito);
export const dispatchUpdateDistrito = dispatch(actions.actionUpdateDistrito);
export const dispatchDeleteDistrito = dispatch(actions.actionDeleteDistrito);
