import { orgaoApi } from '@/api/orgao';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { OrgaoState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetOrgao, commitSetOrgaos, commitUnsetOrgao } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { IOrgao, IOrgaoCreate, IOrgaoUpdate } from '@/interfaces/orgao';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<OrgaoState, State>;

export const actions = {
    async actionGetOrgaos(context: MainContext) {
        try {
            const response = await orgaoApi.getOrgaos(context.rootState.main.token);
            if (response) {
                commitSetOrgaos(context, response.data);
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
    async actionCreateOrgao(context: MainContext, payload: IOrgaoCreate): Promise<boolean> {
        const loadingNotification = { content: 'salvando', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                orgaoApi.createOrgao(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetOrgao(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Órgão cadastrado com sucesso!', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionUpdateOrgao(
        context: MainContext,
        payload: {
            id: number,
            orgao: IOrgaoUpdate,
        },
    ): Promise<boolean> {
        const loadingNotification = { content: 'salvando órgão', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                orgaoApi.updateOrgao(context.rootState.main.token, payload.id, payload.orgao),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetOrgao(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Órgão atualizado com sucesso', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionDeleteOrgao(context: MainContext, orgao: IOrgao): Promise<boolean> {
        const loadingNotification = { content: 'excluindo órgão', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                orgaoApi.deleteOrgao(context.rootState.main.token, orgao.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitUnsetOrgao(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Órgão inativado com sucesso', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            if (apiError.response?.data.detail === 'O órgão selecionado já consta inativo!') {
                commitUnsetOrgao(context, orgao);
            }
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
};

const { dispatch } = getStoreAccessors<OrgaoState, State>('');

export const dispatchGetOrgaos = dispatch(actions.actionGetOrgaos);
export const dispatchCreateOrgao = dispatch(actions.actionCreateOrgao);
export const dispatchUpdateOrgao = dispatch(actions.actionUpdateOrgao);
export const dispatchDeleteOrgao = dispatch(actions.actionDeleteOrgao);
