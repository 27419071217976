import axios from 'axios';
import { apiUrl } from '@/env';
import { IRegiao } from '../interfaces/regiao';
import authHeaders from './auth';

export const regiaoApi = {
  async getRegioes(token: string) {
    return axios.get<IRegiao[]>(`${apiUrl}/api/v1/regioes/`, authHeaders(token));
  },
};
