import { ResponsavelState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    responsaveis: (state: ResponsavelState) => state.responsaveis,
    responsavel: (state: ResponsavelState) => (responsavelId: number) => {
        const responsaveisFiltrados = state.responsaveis.filter((responsavel) =>
            responsavel.id === responsavelId);
        if (responsaveisFiltrados.length > 0) {
            return { ...responsaveisFiltrados[0] };
        }
    },
    responsaveisPorLocalidade: (state: ResponsavelState) => state.responsaveis.filter((responsavel) => {
        return responsavel.por_localidade === true;
    }),
    responsaveisPorPontoAcesso: (state: ResponsavelState) => state.responsaveis.filter((responsavel) => {
        return responsavel.por_ponto_acesso === true;
    }),
};

const { read } = getStoreAccessors<ResponsavelState, State>('');

export const readResponsaveis = read(getters.responsaveis);
export const consultarResponsavel = read(getters.responsavel);
export const readResponsaveisPorLocalidade = read(getters.responsaveisPorLocalidade);
export const readResponsaveisPorPontoAcesso = read(getters.responsaveisPorPontoAcesso);
