import { IEmpresa } from '@/interfaces/empresa';
import { EmpresaState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setEmpresas(state: EmpresaState, payload: IEmpresa[]) {
        state.empresas = payload;
    },
    setEmpresa(state: EmpresaState, payload: IEmpresa) {
        const empresas = state.empresas.filter((empresa: IEmpresa) => empresa.id !== payload.id);
        empresas.push(payload);
        state.empresas = empresas;
    },
    unsetEmpresa(state: EmpresaState, payload: IEmpresa) {
        const empresas = state.empresas.filter((empresa: IEmpresa) => empresa.id !== payload.id);
        state.empresas = empresas;
    },
};

const { commit } = getStoreAccessors<EmpresaState, State>('');

export const commitSetEmpresa = commit(mutations.setEmpresa);
export const commitUnsetEmpresa = commit(mutations.unsetEmpresa);
export const commitSetEmpresas = commit(mutations.setEmpresas);
