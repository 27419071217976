import { SubprefeituraState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    subprefeituras: (state: SubprefeituraState) => state.subprefeituras,
    subprefeitura: (state: SubprefeituraState) => (subprefeituraId: number) => {
        const subprefeiturasFiltradas = state.subprefeituras.filter(
            (subprefeitura) => subprefeitura.id === subprefeituraId);
        if (subprefeiturasFiltradas.length > 0) {
            return { ...subprefeiturasFiltradas[0] };
        }
    },
};

const { read } = getStoreAccessors<SubprefeituraState, State>('');

export const readSubprefeituras = read(getters.subprefeituras);
export const consultarSubprefeitura = read(getters.subprefeitura);
