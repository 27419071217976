import { localidadeApi } from '@/api/localidade';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { LocalidadeState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetMapaLocalidades, commitSetLocalidade, commitSetLocalidades,
    commitSetLocalidadesAppend, commitUnsetLocalidade } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { ILocalidade, ILocalidadeBase, ILocalidadeSelectFilter,
    ILocalidadeCreateUpdate, ILocalidadeHistoricoAlteracoes} from '@/interfaces/localidade';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<LocalidadeState, State>;

export const actions = {
    async actionGetLocalidades(context: MainContext, skip: number) {
        try {
            const response = await localidadeApi.getLocalidades(context.rootState.main.token, skip);
            if (response) {
                const localidades: ILocalidade[] = response.data.map((localidade) => {
                    localidade.qtd_pontos_acesso = localidade.pontos_acesso.filter(x => x.is_active).length;
                    localidade.endereco = `${localidade.tipo_logradouro.descricao} ${localidade.logradouro}`;
                    if (localidade.numero_logradouro) {
                        localidade.endereco += `, ${localidade.numero_logradouro}`;
                    }
                    if (localidade.complemento_logradouro) {
                        localidade.endereco += ` - ${localidade.complemento_logradouro}`;
                    }
                    return localidade;
                });
                if (skip > 0) {
                    commitSetLocalidadesAppend(context, localidades);
                } else {
                    commitSetLocalidades(context, localidades);
                }
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
    async actionGetHistoricoLocalidade(context: MainContext, idLocalidade: number) {
        try {
            const response = await localidadeApi.getHistoricoLocalidade(context.rootState.main.token, idLocalidade);
            if (response) {
                return response.data;
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
    async actionGetMapaLocalidadesPorOrigem(context: MainContext) {
        try {
            const response = await localidadeApi.getMapaLocalidadesPorOrigem(context.rootState.main.token);
            if (response) {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                commitSetMapaLocalidades(context, blob);
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
    async actionGetLocalidadesFiltradas(context: MainContext, payload: ILocalidadeSelectFilter) {
        try {
            const response = await localidadeApi.getLocalidadesFiltrar(
                context.rootState.main.token, payload.skip, payload);
            if (response) {
                const localidades: ILocalidade[] = response.data.map((localidade) => {
                    localidade.qtd_pontos_acesso = localidade.pontos_acesso.length;
                    localidade.endereco = `${localidade.tipo_logradouro.descricao} ${localidade.logradouro}`;
                    if (localidade.numero_logradouro) {
                        localidade.endereco += `, ${localidade.numero_logradouro}`;
                    }
                    if (localidade.complemento_logradouro) {
                        localidade.endereco += ` - ${localidade.complemento_logradouro}`;
                    }
                    return localidade;
                });
                if (payload.skip > 0) {
                    commitSetLocalidadesAppend(context, localidades);
                } else {
                    commitSetLocalidades(context, localidades);
                }
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
    async actionCreateLocalidade(context: MainContext, payload: ILocalidadeBase): Promise<boolean> {
        const loadingNotification = { content: 'salvando localidade', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                localidadeApi.createLocalidade(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetLocalidade(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, {
                content: 'Localidade cadastrada com sucesso!',
                color: 'success',
            });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionUpdateLocalidade(
        context: MainContext,
        payload: {
            id: number,
            localidade: ILocalidadeCreateUpdate,
        },
    ): Promise<boolean> {
        const loadingNotification = { content: 'salvando localidade', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                localidadeApi.updateLocalidade(
                    context.rootState.main.token,
                    payload.id,
                    payload.localidade,
                ),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetLocalidade(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, {
                content: 'Localidade atualizada com sucesso',
                color: 'success',
            });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
    async actionDeleteLocalidade(context: MainContext, localidade: ILocalidade): Promise<boolean> {
        const loadingNotification = { content: 'excluindo localidade', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                localidadeApi.deleteLocalidade(context.rootState.main.token, localidade.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitUnsetLocalidade(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Localidade inativada com sucesso', color: 'success' });
            return true;
        } catch (error) {
            const apiError = error as AxiosError;
            commitRemoveNotification(context, loadingNotification);
            if (apiError.response?.data.detail === 'A localidade informada já consta inativa!') {
                commitUnsetLocalidade(context, localidade);
            }
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
            return false;
        }
    },
};

const { dispatch } = getStoreAccessors<LocalidadeState, State>('');

export const dispatchGetLocalidades = dispatch(actions.actionGetLocalidades);
export const dispatchGetMapaLocalidadesPorOrigem = dispatch(actions.actionGetMapaLocalidadesPorOrigem);
export const dispatchGetLocalidadesFiltradas = dispatch(actions.actionGetLocalidadesFiltradas);
export const dispatchGetHistoricoLocalidade = dispatch(actions.actionGetHistoricoLocalidade);
export const dispatchCreateLocalidade = dispatch(actions.actionCreateLocalidade);
export const dispatchUpdateLocalidade = dispatch(actions.actionUpdateLocalidade);
export const dispatchDeleteLocalidade = dispatch(actions.actionDeleteLocalidade);
