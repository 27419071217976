import { tipoLogradouroApi } from '@/api/tipo_logradouro';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { TipoLogradouroState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetTiposLogradouro } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification } from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<TipoLogradouroState, State>;

export const actions = {
    async actionGetTiposLogradouro(context: MainContext) {
        try {
            const response = await tipoLogradouroApi.getTiposLogradouro(context.rootState.main.token);
            if (response) {
                commitSetTiposLogradouro(context, response.data);
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
};

const { dispatch } = getStoreAccessors<TipoLogradouroState, State>('');

export const dispatchGetTiposLogradouro = dispatch(actions.actionGetTiposLogradouro);
