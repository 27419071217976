import '@babel/polyfill';
// Import Component hooks before component definitions
import './component-hooks';
import Vue from 'vue';
import './plugins/vuetify';
import './plugins/vee-validate';
import App from './App.vue';
import router from './router';
import store from '@/store';
import './registerServiceWorker';
import 'vuetify/dist/vuetify.min.css';
import { dispatchCreateRegistroAcesso } from '@/store/registro_acesso/actions';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

router.afterEach((to, from) => {
  const user = store.state.main.userProfile;
  const token = store.state.main.token;

  if (user) {
    const registroAcesso = {
      id_usuario_acao: user.id,
      email_usuario: user.email,
      token_usuario: token,
      caminho_destino: to.path,
      caminho_origem: from.path,
    };

    dispatchCreateRegistroAcesso(store, registroAcesso);
  }
});
