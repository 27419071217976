import { IStatusImplantacao } from '@/interfaces/status_implantacao';
import { StatusImplantacaoState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setStatusImplantacao(state: StatusImplantacaoState, payload: IStatusImplantacao[]) {
        state.statusImplantacao = payload;
    },
};

const { commit } = getStoreAccessors<StatusImplantacaoState, State>('');

export const commitSetStatusImplantacao = commit(mutations.setStatusImplantacao);
