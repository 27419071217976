import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { TipoLocalidadeState } from './state';

const defaultState: TipoLocalidadeState = {
  tiposLocalidade: [],
};

export const tipoLocalidadeModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
