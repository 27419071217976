import axios from 'axios';
import { apiUrl } from '@/env';
import { IPontoAcesso, IPontoAcessoBase, IPontoAcessoSelectFilter, IPontoAcessoHistoricoAlteracoes } from '../interfaces/ponto_acesso';
import authHeaders from './auth';

export const pontoAcessoApi = {
  async getHistoricoPontoAcesso(token: string, pontoAcessoId: number) {
    return axios.get<IPontoAcessoHistoricoAlteracoes[]>(
      `${apiUrl}/api/v1/ponto_acesso/historico/${pontoAcessoId}`, authHeaders(token));
  },
  async getPontosAcesso(token: string, skip: number) {
    return axios.get<IPontoAcesso[]>(`${apiUrl}/api/v1/ponto_acesso/?skip=${skip}`, authHeaders(token));
  },
  async getMapaPontosAcessoPorResponsavel(token: string) {
    return axios.get<ArrayBuffer>(`${apiUrl}/api/v1/ponto_acesso/pdf-ponto-de-acesso-por-responsavel`,
    authHeaders(token));
  },
  async getPontosAcessoFiltrar(token: string, skip: number, PontoAcessoFiltro: IPontoAcessoSelectFilter) {
    return axios.post(`${apiUrl}/api/v1/ponto_acesso/filtrar?skip=${skip}`, PontoAcessoFiltro, authHeaders(token));
  },
  async createPontoAcesso(token: string, data: IPontoAcessoBase) {
    return axios.post(`${apiUrl}/api/v1/ponto_acesso/`, data, authHeaders(token));
  },
  async updatePontoAcesso(token: string, pontoAcessoId: number, data: IPontoAcessoBase) {
    return axios.put(`${apiUrl}/api/v1/ponto_acesso/${pontoAcessoId}`, data, authHeaders(token));
  },
  async deletePontoAcesso(token: string, pontoAcessoId: number) {
    return axios.delete(`${apiUrl}/api/v1/ponto_acesso/${pontoAcessoId}`, authHeaders(token));
  },
};
