import { LocalidadeState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    localidades: (state: LocalidadeState) => state.localidades,
    mapaLocalidadesPorOrigem: (state: LocalidadeState) => state.mapaLocalidades,
    localidade: (state: LocalidadeState) => (localidadeId: number) => {
        const localidadesFiltradas = state.localidades.filter((localidade) => localidade.id === localidadeId);
        return localidadesFiltradas[0];
    },
};

const { read } = getStoreAccessors<LocalidadeState, State>('');

export const readLocalidades = read(getters.localidades);
export const readMapaLocalidadesPorOrigem = read(getters.mapaLocalidadesPorOrigem);
export const consultarLocalidade = read(getters.localidade);
