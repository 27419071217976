import { ITipoPublicidade } from '@/interfaces/tipo_publicidade';
import { TipoPublicidadeState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setTiposPublicidade(state: TipoPublicidadeState, payload: ITipoPublicidade[]) {
        state.tiposPublicidade = payload;
    },
};

const { commit } = getStoreAccessors<TipoPublicidadeState, State>('');

export const commitSetTiposPublicidade = commit(mutations.setTiposPublicidade);
