import { TipoLocalidadeState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    tiposLocalidade: (state: TipoLocalidadeState) => state.tiposLocalidade,
    tipoLocalidade: (state: TipoLocalidadeState) => (tipoLocalidadeId: number) => {
        const tiposLocalidadeFiltrados = state.tiposLocalidade.filter(
            (tipoLocalidade) => tipoLocalidade.id === tipoLocalidadeId);
        if (tiposLocalidadeFiltrados.length > 0) {
            return { ...tiposLocalidadeFiltrados[0] };
        }
    },
};

const { read } = getStoreAccessors<TipoLocalidadeState, State>('');

export const readTiposLocalidade = read(getters.tiposLocalidade);
export const consultarTipoLocalidade = read(getters.tipoLocalidade);
