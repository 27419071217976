import { regiaoApi } from '@/api/regiao';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { RegiaoState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetRegioes } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification } from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<RegiaoState, State>;

export const actions = {
    async actionGetRegioes(context: MainContext) {
        try {
            const response = await regiaoApi.getRegioes(context.rootState.main.token);
            if (response) {
                commitSetRegioes(context, response.data);
            }
        } catch (error) {
            const apiError = error as AxiosError;
            commitAddNotification(context, { content: apiError.response?.data.detail, color: 'red' });
            await dispatchCheckApiError(context, apiError);
        }
    },
};

const { dispatch } = getStoreAccessors<RegiaoState, State>('');

export const dispatchGetRegioes = dispatch(actions.actionGetRegioes);
