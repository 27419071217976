import axios from 'axios';
import { apiUrl } from '@/env';
import { ITipoLogradouro } from '../interfaces/tipo_logradouro';
import authHeaders from './auth';

export const tipoLogradouroApi = {
  async getTiposLogradouro(token: string) {
    return axios.get<ITipoLogradouro[]>(`${apiUrl}/api/v1/tipo_logradouro/`, authHeaders(token));
  },
};
