import { BairroState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    bairros: (state: BairroState) => state.bairros,
    bairro: (state: BairroState) => (bairroId: number) => {
        const bairrosFiltrados = state.bairros.filter((bairro) => bairro.id === bairroId);
        if (bairrosFiltrados.length > 0) {
            return { ...bairrosFiltrados[0] };
        }
    },
};

const { read } = getStoreAccessors<BairroState, State>('');

export const readBairros = read(getters.bairros);
export const consultarBairro = read(getters.bairro);
