import { IOrgao } from '@/interfaces/orgao';
import { OrgaoState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setOrgaos(state: OrgaoState, payload: IOrgao[]) {
        state.orgaos = payload;
    },
    setOrgao(state: OrgaoState, payload: IOrgao) {
        const orgaos = state.orgaos.filter((orgao: IOrgao) => orgao.id !== payload.id);
        orgaos.push(payload);
        state.orgaos = orgaos;
    },
    unsetOrgao(state: OrgaoState, payload: IOrgao) {
        const orgaos = state.orgaos.filter((orgao: IOrgao) => orgao.id !== payload.id);
        state.orgaos = orgaos;
    },
};

const { commit } = getStoreAccessors<OrgaoState, State>('');

export const commitSetOrgao = commit(mutations.setOrgao);
export const commitUnsetOrgao = commit(mutations.unsetOrgao);
export const commitSetOrgaos = commit(mutations.setOrgaos);
