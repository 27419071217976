import { PontoAcessoState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    pontosAcesso: (state: PontoAcessoState) => state.pontosAcesso,
    mapaPontosAcessoPorResponsavel: (state: PontoAcessoState) => state.mapaPontosAcesso,
    pontoAcesso: (state: PontoAcessoState) => (pontoAcessoId: number) => {
        const pontosAcessoFiltrados = state.pontosAcesso.filter((pontoAcesso) =>
            pontoAcesso.id === pontoAcessoId);
        if (pontosAcessoFiltrados.length > 0) {
            return { ...pontosAcessoFiltrados[0] };
        }
    },
};

const { read } = getStoreAccessors<PontoAcessoState, State>('');

export const readPontosAcesso = read(getters.pontosAcesso);
export const readMapaPontosAcessoPorResponsavel = read(getters.mapaPontosAcessoPorResponsavel);
export const consultarPontoAcesso = read(getters.pontoAcesso);
