import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { OrigemLocalidadeState } from './state';

const defaultState: OrigemLocalidadeState = {
  origemLocalidadeLista: [],
};

export const origemLocalidadeModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
