import Vue from 'vue';
import Router from 'vue-router';

import RouterComponent from './components/RouterComponent.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "start" */ './views/main/Start.vue'),
      children: [
        {
          path: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
          path: 'recover-password',
          component: () => import(/* webpackChunkName: "recover-password" */ './views/PasswordRecovery.vue'),
        },
        {
          path: 'reset-password',
          component: () => import(/* webpackChunkName: "reset-password" */ './views/ResetPassword.vue'),
        },
        {
          path: 'main',
          component: () => import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
          children: [
            {
              path: 'dashboard',
              component: () => import(/* webpackChunkName: "main-dashboard" */ './views/main/Dashboard.vue'),
            },
            {
              path: 'profile',
              component: RouterComponent,
              redirect: 'profile/view',
              children: [
                {
                  path: 'view',
                  component: () => import(
                    /* webpackChunkName: "main-profile" */ './views/main/profile/UserProfile.vue'),
                },
                {
                  path: 'edit',
                  component: () => import(
                    /* webpackChunkName: "main-profile-edit" */ './views/main/profile/UserProfileEdit.vue'),
                },
                {
                  path: 'password',
                  component: () => import(
                    /* webpackChunkName: "main-profile-password" */ './views/main/profile/UserProfileEditPassword.vue'),
                },
              ],
            },
            {
              path: 'admin',
              component: () => import(/* webpackChunkName: "main-admin" */ './views/main/admin/Admin.vue'),
              redirect: 'admin/users/all',
              children: [
                {
                  path: 'users',
                  redirect: 'users/all',
                },
                {
                  path: 'users/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/AdminUsers.vue'),
                },
                {
                  path: 'users/edit/:id',
                  name: 'main-admin-users-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-edit" */ './views/main/admin/EditUser.vue'),
                },
                {
                  path: 'users/create',
                  name: 'main-admin-users-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-create" */ './views/main/admin/CreateUser.vue'),
                },
              ],
            },
            {
              path: 'empresas',
              component: () => import(/* webpackChunkName: "main-empresas" */ './views/main/empresa/Empresas.vue'),
              redirect: 'empresas/listar',
              children: [
                {
                  path: 'listar',
                  component: () => import(
                    /* webpackChunkName: "main-empresas" */ './views/main/empresa/ListarEmpresas.vue'),
                },
                {
                  path: 'cadastrar',
                  name: 'main-empresas-cadastrar',
                  component: () => import(
                    /* webpackChunkName: "main-empresas-cadastrar" */ './views/main/empresa/CadastrarEmpresa.vue'),
                },
                {
                  path: 'alterar/:id',
                  name: 'main-empresas-alterar',
                  component: () => import(
                    /* webpackChunkName: "main-empresas-alterar" */ './views/main/empresa/AlterarEmpresa.vue'),
                },
              ],
            },
            {
              path: 'orgaos',
              component: () => import(/* webpackChunkName: "main-orgaos" */ './views/main/orgao/Orgaos.vue'),
              redirect: 'orgaos/listar',
              children: [
                {
                  path: 'listar',
                  component: () => import(
                    /* webpackChunkName: "main-orgaos" */ './views/main/orgao/ListarOrgaos.vue'),
                },
                {
                  path: 'cadastrar',
                  name: 'main-orgaos-cadastrar',
                  component: () => import(
                    /* webpackChunkName: "main-orgaos-cadastrar" */ './views/main/orgao/CadastrarOrgao.vue'),
                },
                {
                  path: 'alterar/:id',
                  name: 'main-orgaos-alterar',
                  component: () => import(
                    /* webpackChunkName: "main-orgaos-alterar" */ './views/main/orgao/AlterarOrgao.vue'),
                },
              ],
            },
            {
              path: 'tiposLocalidade',
              component: () => import(/* webpackChunkName: "main-tiposLocalidade" */ './views/main/tipo_localidade/TiposLocalidade.vue'),
              redirect: 'tiposLocalidade/listar',
              children: [
                {
                  path: 'listar',
                  component: () => import(
                    /* webpackChunkName: "main-tiposLocalidade" */ './views/main/tipo_localidade/ListarTiposLocalidade.vue'),
                },
                {
                  path: 'cadastrar',
                  name: 'main-tiposLocalidade-cadastrar',
                  component: () => import(
                    /* webpackChunkName: "main-tiposLocalidade-cadastrar" */ './views/main/tipo_localidade/CadastrarTipoLocalidade.vue'),
                },
                {
                  path: 'alterar/:id',
                  name: 'main-tiposLocalidade-alterar',
                  component: () => import(
                    /* webpackChunkName: "main-tiposLocalidade-alterar" */ './views/main/tipo_localidade/AlterarTipoLocalidade.vue'),
                },
              ],
            },
            {
              path: 'origem_localidade',
              component: () => import(/* webpackChunkName: "main-origem-localidade" */ './views/main/origem_localidade/OrigemLocalidade.vue'),
              redirect: 'origem_localidade/listar',
              children: [
                {
                  path: 'listar',
                  component: () => import(
                    /* webpackChunkName: "main-origem-localidade" */ './views/main/origem_localidade/ListarOrigemLocalidade.vue'),
                },
                {
                  path: 'cadastrar',
                  name: 'main-origem-localidade-cadastrar',
                  component: () => import(
                    /* webpackChunkName: "main-origem-localidade-cadastrar" */ './views/main/origem_localidade/CadastrarOrigemLocalidade.vue'),
                },
                {
                  path: 'alterar/:id',
                  name: 'main-origem-localidade-alterar',
                  component: () => import(
                    /* webpackChunkName: "main-origem-localidade-alterar" */ './views/main/origem_localidade/AlterarOrigemLocalidade.vue'),
                },
              ],
            },
            {
              path: 'responsavel',
              component: () => import(/* webpackChunkName: "main-responsavel" */ './views/main/responsavel/Responsavel.vue'),
              redirect: 'responsavel/listar',
              children: [
                {
                  path: 'listar',
                  component: () => import(
                    /* webpackChunkName: "main-responsavel" */ './views/main/responsavel/ListarResponsaveis.vue'),
                },
                {
                  path: 'cadastrar',
                  name: 'main-responsavel-cadastrar',
                  component: () => import(
                    /* webpackChunkName: "main-responsavel-cadastrar" */ './views/main/responsavel/CadastrarResponsavel.vue'),
                },
                {
                  path: 'alterar/:id',
                  name: 'main-responsavel-alterar',
                  component: () => import(
                    /* webpackChunkName: "main-responsavel-alterar" */
                    './views/main/responsavel/AlterarResponsavel.vue'),
                },
              ],
            },
            {
              path: 'localidade',
              component: () => import(
                /* webpackChunkName: "main-localidade" */ './views/main/localidade/Localidade.vue'),
              redirect: 'localidade/listar',
              children: [
                {
                  path: 'listar',
                  component: () => import(
                    /* webpackChunkName: "main-localidade" */ './views/main/localidade/ListarLocalidades.vue'),
                },
                {
                  path: 'cadastrar',
                  name: 'main-localidade-cadastrar',
                  component: () => import(
                    /* webpackChunkName: "main-localidade-cadastrar" */ './views/main/localidade/CadastrarLocalidade.vue'),
                },
                {
                  path: 'alterar/:id',
                  name: 'main-localidade-alterar',
                  component: () => import(
                    /* webpackChunkName: "main-localidade-alterar" */
                    './views/main/localidade/AlterarLocalidade.vue'),
                },
              ],
            },
            {
              path: 'ponto_acesso',
              component: () => import(
                /* webpackChunkName: "main-ponto-acesso" */ './views/main/ponto_acesso/PontoAcesso.vue'),
              redirect: 'ponto_acesso/listar',
              children: [
                {
                  path: 'listar',
                  name: 'main-ponto-acesso',
                  component: () => import(
                    /* webpackChunkName: "main-ponto-acesso" */ './views/main/ponto_acesso/ListarPontosAcesso.vue'),
                },
                {
                  path: 'cadastrar',
                  name: 'main-ponto-acesso-cadastrar',
                  component: () => import(
                    /* webpackChunkName: "main-ponto-acesso-cadastrar" */ './views/main/ponto_acesso/CadastrarPontoAcesso.vue'),
                },
                {
                  path: 'alterar/:id',
                  name: 'main-ponto-acesso-alterar',
                  component: () => import(
                    /* webpackChunkName: "main-ponto-acesso-alterar" */
                    './views/main/ponto_acesso/AlterarPontoAcesso.vue'),
                },
              ],
            },
            {
              path: 'mapas',
              component: () => import(
                /* webpackChunkName: "main-mapas" */ './views/main/mapas/GerarMapas.vue'),
            },
            {
              path: 'distritos',
              component: () => import(/* webpackChunkName: "main-distritos" */ './views/main/distrito/Distritos.vue'),
              redirect: 'distritos/listar',
              children: [
                {
                  path: 'listar',
                  component: () => import(
                    /* webpackChunkName: "main-distritos" */ './views/main/distrito/ListarDistritos.vue'),
                },
                {
                  path: 'cadastrar',
                  name: 'main-distritos-cadastrar',
                  component: () => import(
                    /* webpackChunkName: "main-distritos-cadastrar" */ './views/main/distrito/CadastrarDistrito.vue'),
                },
                {
                  path: 'alterar/:id',
                  name: 'main-distritos-alterar',
                  component: () => import(
                    /* webpackChunkName: "main-distritos-alterar" */ './views/main/distrito/AlterarDistrito.vue'),
                },
              ],
            },
            {
              path: 'bairros',
              component: () => import(/* webpackChunkName: "main-bairros" */ './views/main/bairro/Bairros.vue'),
              redirect: 'bairros/listar',
              children: [
                {
                  path: 'listar',
                  component: () => import(
                    /* webpackChunkName: "main-bairros" */ './views/main/bairro/ListarBairros.vue'),
                },
                {
                  path: 'cadastrar',
                  name: 'main-bairros-cadastrar',
                  component: () => import(
                    /* webpackChunkName: "main-bairros-cadastrar" */ './views/main/bairro/CadastrarBairro.vue'),
                },
                {
                  path: 'alterar/:id',
                  name: 'main-bairros-alterar',
                  component: () => import(
                    /* webpackChunkName: "main-bairros-alterar" */ './views/main/bairro/AlterarBairro.vue'),
                },
              ],
            },
            {
              path: 'subprefeituras',
              component: () => import(/* webpackChunkName: "main-subprefeituras" */ './views/main/subprefeitura/Subprefeituras.vue'),
              redirect: 'subprefeituras/listar',
              children: [
                {
                  path: 'listar',
                  component: () => import(
                    /* webpackChunkName: "main-subprefeituras" */
                    './views/main/subprefeitura/ListarSubprefeituras.vue'),
                },
                {
                  path: 'cadastrar',
                  name: 'main-subprefeituras-cadastrar',
                  component: () => import(
                    /* webpackChunkName: "main-subprefeituras-cadastrar" */
                    './views/main/subprefeitura/CadastrarSubprefeitura.vue'),
                },
                {
                  path: 'alterar/:id',
                  name: 'main-subprefeituras-alterar',
                  component: () => import(
                    /* webpackChunkName: "main-subprefeituras-alterar" */ './views/main/subprefeitura/AlterarSubprefeitura.vue'),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/*', redirect: '/',
    },
  ],
});
